<template>
  <v-card tile flat>
    <v-card-text v-if="comentarios.length > 0" class="pt-6">
      <v-card
        class="mb-3"
        v-for="(comentario, i) in comentarios"
        :key="i"
        flat
        outlined
      >
        <v-card-text class="grey lighten-5">
          <div class="px-6 py-2 d-flex align-center justify-center">
            <span class="text-subtitle-1">
              <span class="text-h5 font-weight-bold">{{
                comentario.assunto_descricao
              }}</span>
              -
              {{ comentario.nome_usuario }}
            </span>
            <v-spacer></v-spacer>
            {{ comentario.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
          </div>
          <v-divider> </v-divider>
          <div class="pa-6">
            {{ comentario.comentario }}
          </div>
          <div class="d-flex pt-6 px-6">
            <div v-for="(midia, j) in comentario.midias" :key="j">
              <v-btn icon v-if="midia.tipo == 'pdf'" :href="midia.url">
                <v-icon>
                  mdi-file-pdf-box
                </v-icon>
              </v-btn>
              <v-btn
                icon
                v-else-if="midia.tipo == 'jpg' || midia.tipo == 'png'"
                @click="openImage(midia.url)"
              >
                <v-icon>
                  mdi-image
                </v-icon>
              </v-btn>
              <v-btn icon v-else target="_blank" :href="midia.url">
                <v-icon>
                  mdi-file-document-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text v-else>
      Nenhum comentario enviado.
    </v-card-text>
    <DialogImage
      v-if="dialogImage"
      :dialogImage.sync="dialogImage"
      :item="image"
    />
  </v-card>
</template>

<script>
import { fetchComentarios } from "@/api/cliente/comentarios.js";
import { mapState } from "vuex";
export default {
  name: "EnviadasComentarios",
  data() {
    return {
      comentarios: [],
      image: null,
      loading: false,
      dialogImage: false,
    };
  },
  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },
  components: {
    DialogImage: () => import("./DialogImage.vue"),
  },
  methods: {
    openImage(item) {
      this.dialogImage = true;
      this.image = item;
    },
    getComentariosAll() {
      this.loading = true;
      return fetchComentarios(`?user_id=${this.user_id}`)
        .then((response) => {
          this.comentarios = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getComentariosAll();
  },
};
</script>

<style></style>
